/* Vars */

/* Homepage Container */

/* light blue #1550C6 */

.master {
  width: 100%;
}

@media (max-width: 500px) {
  .master {
    width: 440px;
  }
}

.body-container {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

@media (max-width: 500px) {
  .body-container {
    max-width: 100vw;
    margin: 0;
    width: 100vw;
  }
}

.home-container .home-image img {
  width: min-content;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 875px;
  object-fit: cover;
}

@media (max-width: 500px) {
  .home-container .home-image img {
    width: min-content;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 875px;
    object-fit: cover;
  }
}

.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #013da5;
  width: 100%;
  position: relative;
}

.home-info {
  color: #232f47;
  padding: 0px 10% 2.5rem;
  position: absolute;
  background: #ffffff;
  margin: 0 0 0 20px;
  top: 250px;
}

.home-info p {
  display: flex;
  align-items: center;
  color: #232f47;
  width: 625px;
}

.home-info h2 {
  font-weight: 700;
  color: #232f47;
  font-size: 30px;
  margin: 50px 0 25px 0;
  text-transform: capitalize;
}

.home-info .home-info-button {
  background: none;
  border: 2px solid #566e8c;
  font: inherit;
  line-height: 1;
  padding: 1em 2em;
  margin: 0.5em 2em 0 0;
  border-radius: 0;
  font-weight: 400;
  font-size: 14px;
  border-color: var(--color);
  transition: 0.25s;
}

.home-info .home-info-button:hover,
.home-info .home-info-button:focus {
  border-color: var(--hover);
  color: #fff;
}

@media (max-width: 500px) {
  .home-container {
    flex-direction: column;
    justify-content: center;
    background-color: #013da5;
    width: 100%;
  }

  .home-info {
    margin: 0 auto;
    width: 90%;
  }

  .home-info p {
    width: 100%;
  }
}

.home-container img {
  max-width: 100%;
  max-height: 100%;
}

img {
  display: block;
}

/* Header Container */

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0em 2.5em;
}

.header-menu {
  text-decoration: none;
}

.header-menu h1 {
  font-size: 14px;
  color: #ffffff;
  border-color: #013da5;
  line-height: 60px;
  background-color: #013da5;
  text-transform: capitalize;
  padding: 0 38px;
  font-weight: 500;
  text-decoration: none;
}

.header-menu h1:hover {
  color: #ffffff;
  cursor: pointer;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  opacity: 0.9;
}

.header-nav {
  display: flex;
  justify-content: first baseline;
}


.header-nav-links:hover,
.header-nav-links:focus,
.header-nav-links:active {
  text-decoration: none;
  color: #070234;
}
.header-nav-links {
  text-decoration: none;
  transition: color 0.1s, background-color 0.1s;
}

.header-nav-links {
  color: #040910;
  font-size: 22px;
  margin-right: 20px;
  font-weight: 500;
  position: relative;
  display: block;
  padding: 16px 0;
  transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
}

.header-nav-links::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 3px;
  font-size: 20px;
  width: 100%;
  background-color: var(--blue-brand);
  transform-origin: right top;
  transform: scale(0, 1);
  transition: color 0.1s, transform 0.2s ease-out;
}

.header-nav-links:active::before {
  background-color: var(--blue-brand);
}

.header-nav-links:hover::before,
.header-nav-links:focus::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.header-nav-links:hover,
.header-nav-links:focus,
.header-nav-links:active {
  text-decoration: none;
  color: #000000;
}

.header-house-svg {
  width: 100%;
  max-width: 75px;
}

.header-prmg-logo {
  width: 275px;
}

.header-logo img {
  border-right: 2px solid #f3f6f9;
  padding: 20px 50px 20px 0;
  width: 350px;
}

@media (max-width: 500px) {
  .header-container {
    flex-direction: column;

    width: 100%;
    margin: 0;
    max-width: 100vw;

  }

  .header-menu {
    width: 80%;
    margin: 20px auto 0;
    text-align: center;
  }

  .header-menu h1 {
    padding: 0;
  }

  .header-logo img {
    border: none;
    padding: 20px 0 0 0;
    width: 90%;
    margin: 0 auto;
  }
}

/* Loan options */

.container-loans {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0 0;
}

.container-loans h1 {
  color: #232f47;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 100px;
  font-size: 40px;
}

.container-loans .icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container-loans ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 85%;
}

.container-loans ul .icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #f3f6f9;
  padding: 1.5rem 2rem;
  margin-bottom: 3.5rem;
  width: 40%;
}

.container-loans ul .icons:hover {
  border: 2px solid #2364d2;
}

.container-loans ul .icons li strong {
  color: #013da5;
  font-weight: 700;
}

.container-loans ul .icons:last-child {
  margin: 0 auto;
  margin-bottom: 5rem;
}

.container-loans ul .icons li {
  font-size: 15px;
}

@media (max-width: 500px) {
  .container-loans {
    flex-direction: column;
    align-items: center;
  }

  .container-loans h1 {
    width: 90%;
    padding-left: 9%;
  }

  .container-loans .icons {
    flex-direction: column;
    align-items: center;
  }

  .container-loans ul .icons {
    width: 90%;
  }
}

/* Apply Page */
.appContainer {
  padding: 50px;
  border-top: 1px solid #f3f6f9;
}

.appContainerChild {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appContainer select {
  border: 2px solid;
  color: #d9e1f6;
  width: 25%;
  height: 50px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
}

.appFooter .appButton {
  background: none;
  border: 2px solid #566e8c;
  font: inherit;
  line-height: 1;
  padding: 1em 2em;
  margin: 0.5em 0 0 0;
  border-radius: 0;
  font-weight: 400;
  font-size: 14px;
  border-color: var(--color);
  transition: 0.25s;
}

.appFooter .appButton:hover,
.appFooter .appButton:focus {
  border-color: #2364d2;
  color: #fff;
}

.appFooter .slide:hover,
.appFooter .slide:focus {
  box-shadow: inset 12.5em 0 0 0 #2364d2;
}

.appFooter .slide {
  --color: #2364d2;
  --hover: #2364d2;
}

.testimonials {
  color: white;
  text-decoration: none;
}

.logos {
  width: 25px;
  margin: 0 auto;
  padding-bottom: 2%;
}

/* About page */
.about-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 50px;
  border-top: 1px solid #f3f6f9;
}

.about-container .about-pic img {
  border: 1px solid #f3f6f9;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 5px;
}

.about-container .about-pic p {
  color: #232f47;
  line-height: 1.5em;
}

.about-container .about-pic .name {
  font-size: 2.5em;
  color: #232f47;
  font-weight: 700;
  margin: 15px 0 0 0;
}

.about-container .about-pic .title {
  font-size: 1.5em;
  font-weight: 500;
}

.about-container .about-pic .license {
  color: #566e8c;
}

.about-container .about-pic .email a,
.about-container .about-pic .phone a {
  text-decoration: none;
  font-size: 1em;
}

.about-container .about-bio {
  margin: 0 100px;
  width: 100%;
}

.about-container .about-bio .slogan {
  color: #232f47;
  font-weight: 700;
}

.about-container .about-bio .about-paragraph {
  line-height: 2.8em;
  color: #232f47;
}

@media (max-width: 500px) {
  .about-container {
    flex-direction: column;
    padding: 50px;
  }

  .about-container .about-pic img {
    width: 40%;
    margin: 0 auto;
  }

  .about-container .about-pic p,
  .about-container .about-pic .name,
  .about-container .about-pic .title,
  .about-container .about-pic .email,
  .about-container .about-pic .phone {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

/* Footer */

.footer-container {
  margin: 0;
  padding: 0;
  border-top: 1px solid #f3f6f9;
  border-bottom: 1px solid #f3f6f9;
}

.footer-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #ffffff;
  background: #013da5;
  padding: 50px;
}

.footer-info .footer-block {
  padding: 0 100px 0 0;
}

.footer-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.footer-logo h1 {
  font-size: 14px;
  color: #ffffff;
  border-color: #013da5;
  line-height: 60px;
  background-color: #013da5;
  text-transform: capitalize;
  padding: 0 38px;
  font-weight: 450;
  text-decoration: none;
}

.footer-logo h1:hover {
  color: #ffffff;
  cursor: pointer;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  opacity: 0.9;
}

.footer-logo img {
  padding: 0 0 25px 0;
  width: 250px;
}

.footer-block h4 {
  font-size: 20px;
  font-weight: 600;
}

.footer-block p {
  font-size: 14px;
  font-weight: 400;
}

.footer-copyright {
  color: #566e8c;
  padding: 20px 0 15px 20px;
}

.footer-copyright p {
  color: #111111;
  font-size: 14px;
  margin-right: 25px;
  text-transform: capitalize;
  opacity: 0.7;
}

@media (max-width: 500px) {
  .footer-container {
    margin: 0;
    padding: 0;
  }

  .footer-info {
    flex-direction: column;
  }

  .footer-logo {
    flex-direction: column;
    padding: 30px;
  }

  .footer-logo h1 {
    padding: 0 38px;
  }
}

/* Footer Disclosures */

.footer-disclosures {
  padding: 20px;
  border-top: 1px solid #e9edf3;
  border-bottom: 1px solid #e9edf3;
  font-size: var(--text-tiny-font-size);
  font-weight: 400;
  line-height: var(--text-tiny-line-height);
}

.footer-disclosures a {
  color: #566e8c;
  display: inline-block;
  padding-right: 24px;
  text-decoration: none;
}

.footer-disclosures a:hover {
  color: #0038df;
}

/* Icons */

.gg-check {
  color: #013da5;
}

